import React from 'react';

const XelerateSidebarLogoFull = () => (
  <svg
    width="195"
    height="40"
    viewBox="0 0 195 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.6091 36.909H33.634L17.1451 18.846L33.634 0.782959H39.6091L23.1201 18.846L39.6091 36.909Z"
      stroke="#1EEF97"
      strokeWidth="1.2375"
      strokeMiterlimit="8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H5.96063L22.41 18.819L5.96063 37.638H0L16.4494 18.819L0 0Z"
      fill="#1EEF97"
    />
    <path
      d="M43.956 10.447H55.98V12.307H46.14V18.067H54.78V19.927H46.14V25.867H56.148V27.727H43.956V10.447ZM62.8663 10.447H65.0743V25.795H74.6743V27.727H62.8663V10.447ZM81.1204 10.447H93.1444V12.307H83.3044V18.067H91.9444V19.927H83.3044V25.867H93.3124V27.727H81.1204V10.447ZM108.383 10.447C109.887 10.447 111.139 10.883 112.139 11.755C113.147 12.627 113.651 13.807 113.651 15.295C113.651 16.695 113.203 17.827 112.307 18.691C111.419 19.555 110.287 20.039 108.911 20.143L113.651 27.367V27.727H111.395L106.559 20.191H102.203V27.727H100.031V10.447H108.383ZM102.203 18.343H108.071C109.103 18.343 109.931 18.083 110.555 17.563C111.179 17.035 111.491 16.291 111.491 15.331C111.491 14.371 111.179 13.627 110.555 13.099C109.931 12.571 109.103 12.307 108.071 12.307H102.203V18.343ZM125.539 10.447H128.371L134.683 27.367V27.727H132.487L130.663 22.759H123.187L121.375 27.727H119.239V27.367L125.539 10.447ZM123.823 20.863H130.027L127.027 12.655H126.835L123.823 20.863ZM138.019 10.447H152.707V12.355H146.467V27.727H144.259V12.355H138.019V10.447ZM159.129 10.447H171.153V12.307H161.313V18.067H169.953V19.927H161.313V25.867H171.321V27.727H159.129V10.447Z"
      fill="white"
    />
  </svg>
);

export default XelerateSidebarLogoFull;
