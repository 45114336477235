import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { DefaultCatalogPageProps } from '@backstage/plugin-catalog';
import { rootRouteRef } from './routes';

/** @public */
export const xelerateCatalogPlugin = createPlugin({
  id: 'xelerate-catalog-plugin',
  routes: {
    root: rootRouteRef,
  },
});

/** @public */
export const CatalogIndexPage: (props: DefaultCatalogPageProps) => JSX.Element =
  xelerateCatalogPlugin.provide(
    createRoutableExtension({
      name: 'XelerateCatalogIndexPage',
      component: () =>
        import('./components/XelerateCatalogPage').then(
          m => m.XelerateCatalogPage,
        ),
      mountPoint: rootRouteRef,
    }),
  );
