import React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SidebarItem } from '@backstage/core-components';
import { useSidebarOpenState } from '@backstage/core-components';
import PopupMenu from '../reusable/popup-menu/PopupMenu';
import { SidebarSubmenuProps } from './sidebar-submenu.types';
import { SUBMENU_OPTIONS } from './sidebar-submenu.constants';
import { useSidebarSubmenuStyles } from './sidebar-submenu.styles';

export const SidebarSubmenu = (props: SidebarSubmenuProps) => {
  const {
    kind,
    title,
    Icon,
    sidebarOptions,
    isHoveredOn,
    submenuRef,
    popupOpen,
    onSetIsHoveredOn,
    onHandleClick,
    onHandlePopupClickAway,
    onHandlePopupEnter,
    onHandlePopupLeave,
  } = props;
  const classes = useSidebarSubmenuStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <>
      <div
        className={classes.supportContainer}
        onPointerLeave={() => onSetIsHoveredOn(SUBMENU_OPTIONS.none)}
      >
        <SidebarItem
          onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
            onHandleClick(e, kind)
          }
          icon={() => <Icon />}
          text={title}
          className={`${classes.supportItem}${isHoveredOn ? '--active' : ''} ${props.highlighted ? classes.highlighted : ''}`}
        >
          {!isHoveredOn && (
            <ArrowRightIcon
              className={classes.arrowIcon}
              sx={isOpen ? { right: '1.5rem' } : { right: '.25rem' }}
            />
          )}
        </SidebarItem>
      </div>
      <PopupMenu
        title={title}
        options={sidebarOptions}
        open={popupOpen}
        anchorEl={submenuRef.current}
        onClickAway={onHandlePopupClickAway}
        onEnter={onHandlePopupEnter}
        onLeave={onHandlePopupLeave}
      />
    </>
  );
};
