/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Based on https://github.com/backstage/backstage/blob/aaf5cb2ca122c0c754ff25e415627b4c54e4e102/packages/core-components/src/layout/HeaderTabs/HeaderTabs.tsx
 * Modified for vertical layout.
 *
 */
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { TabProps } from '@material-ui/core/Tab';
import { TabsClassKey } from '@material-ui/core/Tabs';
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Collapse } from '@material-ui/core';
import { SubRoute, SubRouteHeader } from './SidebarNavExt';

/** @public */
export type HeaderTabsClassKey =
  | 'tabsWrapper'
  | 'defaultTab'
  | 'selected'
  | 'tabRoot'
  | 'wrapper';

const useStyles = makeStyles(
  (theme: Theme) => ({
    tabsWrapper: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      fontWeight: 'bolder',
      gridArea: 'pageSubheader',
      minWidth: '300px',
      maxWidth: '350px',
    },
    defaultTab: {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
      maxWidth: 'unset',
      textTransform: 'none',
      width: '100%',
    },
    selected: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    tabRoot: {
      textAlign: 'left',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
    flexContainer: {
      alignItems: 'flex-start',
    },
    wrapper: {
      alignItems: 'baseline',
    },
  }),
  { name: 'BackstageHeaderTabs' },
);

export type Tab = {
  id: string;
  label: string;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

type HeaderTabsProps = {
  tab: SubRouteHeader;
  onChange?: (subRoute: SubRoute) => void;
  selectedPath: string;
};

/**
 * Vertical Tabs component
 *
 * @public
 *
 */
export function SidebarTabsExt(props: HeaderTabsProps) {
  const { tab, onChange, selectedPath } = props;
  const styles: ClassNameMap<
    HeaderTabsClassKey | Extract<TabsClassKey, 'flexContainer'>
  > = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleSubRouteClick = (subRoute: SubRoute) => {
    //        console.log(subRoute);
    if (onChange) {
      onChange(subRoute);
    }
  };

  return (
    <Box sx={{ display: 'flex' }} className={styles.tabsWrapper}>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: 'dark',
            primary: { main: 'rgb(102, 157, 246)' },
            background: { paper: 'rgb(5, 30, 52)' },
          },
        })}
      >
        <Paper elevation={0} sx={{ width: 320, borderRadius: 0 }}>
          <List component="nav" disablePadding>
            <Divider />
            <Box
              sx={{
                bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
                pb: open ? 2 : 0,
              }}
            >
              <ListItemButton
                alignItems="flex-start"
                onClick={() => setOpen(!open)}
                sx={{
                  px: 3,
                  pt: 2.0,
                  pb: 2.0,
                  '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
                }}
              >
                <ListItemText
                  primary={tab.title}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: '20px',
                    mb: '2px',
                    textTransform: 'uppercase',
                  }}
                  sx={{ my: 0 }}
                />
                <KeyboardArrowDown />
              </ListItemButton>
              {tab.subroutes.map((subRoute, indexSub) => (
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <div key={indexSub}>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{
                          pl: 4,
                          py: 0,
                          minHeight: 32,
                          color: 'rgba(255,255,255,.8)',
                          ...(selectedPath === subRoute.title && {
                            backgroundColor: 'rgba(71, 98, 130, 0.75)',
                          }),
                        }}
                      >
                        <ListItemText
                          primary={subRoute.title}
                          primaryTypographyProps={{
                            fontSize: 14,
                            fontWeight: '500',
                            lineHeight: '26px',
                          }}
                          onClick={() => {
                            handleSubRouteClick(subRoute);
                          }}
                        />
                      </ListItemButton>
                    </List>
                  </div>
                </Collapse>
              ))}
            </Box>
          </List>
        </Paper>
      </ThemeProvider>
    </Box>
  );
}
