/*
 * Based on TechDocsReaderPageHeader
 * https://github.com/backstage/backstage/blob/master/plugins/techdocs/src/reader/components/TechDocsReaderPageHeader/TechDocsReaderPageHeader.tsx
 *
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren, useEffect } from 'react';
import Helmet from 'react-helmet';

import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@mui/material/styles';

import {
  useTechDocsReaderPage,
  TechDocsEntityMetadata,
  TechDocsMetadata,
} from '@backstage/plugin-techdocs-react';
import { CompoundEntityRef } from '@backstage/catalog-model';
import { Header } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { capitalizeAndRemoveHyphens } from '../../utils/string.utils';
import useTechDocsMetadata from '../../hooks/useTechDocsMetadata';
import { calculateDaysAgo } from '../../utils/date.utils';

const skeleton = <Skeleton animation="wave" variant="text" height={40} />;

const useStyles = makeStyles(() => ({
  infoContainer: {
    display: 'flex',
    width: '100%',
    marginRight: '20px',
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '50px',
  },
  infoLabel: {
    fontWeight: 'bold',
    color: '#f9f9f9',
  },
  infoContent: {
    color: '#f9f9f9',
  },
}));

/**
 * Props for {@link TechDocsReaderPageHeader}
 *
 * @public
 * @deprecated No need to pass down properties anymore. The component consumes data from `TechDocsReaderPageContext` instead. Use the {@link @backstage/plugin-techdocs-react#useTechDocsReaderPage} hook for custom header.
 */
export type TechDocsReaderPageHeaderProps = PropsWithChildren<{
  entityRef?: CompoundEntityRef;
  entityMetadata?: TechDocsEntityMetadata;
  techDocsMetadata?: TechDocsMetadata;
}>;

/**
 * Renders the reader page header.
 * This component does not accept props, please use
 * the Tech Docs add-ons to customize it
 * @public
 */
export const DxpReaderPageHeader = (props: TechDocsReaderPageHeaderProps) => {
  const {
    palette: {},
  } = useTheme();
  const { children } = props;
  const configApi = useApi(configApiRef);

  const {
    title,
    setTitle,
    subtitle,
    setSubtitle,
    metadata: { value: metadata, loading: metadataLoading },
    entityMetadata: { value: entityMetadata, loading: entityMetadataLoading },
  } = useTechDocsReaderPage();

  const techdochRef = React.useMemo(
    () => ({
      kind: entityMetadata?.kind ? (entityMetadata?.kind as string) : '',
      namespace: entityMetadata?.metadata.namespace
        ? entityMetadata?.metadata.namespace
        : 'default',
      name: entityMetadata?.metadata.name
        ? (entityMetadata?.metadata.name as string)
        : '',
    }),
    [
      entityMetadata?.kind,
      entityMetadata?.metadata.name,
      entityMetadata?.metadata.namespace,
    ],
  );
  const { metadata: techDocMetadata } = useTechDocsMetadata(techdochRef);

  const classes = useStyles();

  useEffect(() => {
    if (!metadata) return;
    setTitle(metadata.site_name);
    setSubtitle(() => {
      let { site_description } = metadata;
      if (!site_description || site_description === 'None') {
        site_description = '';
      }
      return site_description;
    });
  }, [metadata, setTitle, setSubtitle]);

  const appTitle = configApi.getOptional('app.title') || 'Backstage';
  const tabTitle = [title, subtitle, appTitle].filter(Boolean).join(' | ');

  // If there is no entity or techdocs metadata, there's no reason to show the
  // header (hides the header on 404 error pages).
  const noEntMetadata = !entityMetadataLoading && entityMetadata === undefined;
  const noTdMetadata = !metadataLoading && metadata === undefined;
  if (noEntMetadata || noTdMetadata) return null;

  const kind = entityMetadata?.kind || '';
  const component = entityMetadata?.metadata.title || '';
  const owner = capitalizeAndRemoveHyphens(
    entityMetadata?.spec?.owner?.toString() || '',
  );

  return (
    <>
      <Header
        title={<div style={{ fontSize: '1.5rem' }}>{title || skeleton}</div>}
        subtitle={
          subtitle === '' ? undefined : (
            <div style={{ fontSize: '1.5rem' }}>{subtitle || skeleton}</div>
          )
        }
      >
        <Helmet titleTemplate="%s">
          <title>{tabTitle}</title>
        </Helmet>
        <Box className={classes.infoContainer}>
          {children}
          {component && kind && (
            <Box className={classes.infoItem}>
              <Typography className={classes.infoLabel}>{kind}</Typography>
              <Typography className={classes.infoContent}>
                {component}
              </Typography>
            </Box>
          )}
          {owner && (
            <Box className={classes.infoItem}>
              <Typography className={classes.infoLabel}>Owner</Typography>
              <Typography className={classes.infoContent}>{owner}</Typography>
            </Box>
          )}
          {techDocMetadata && (
            <Box className={classes.infoItem}>
              <Typography className={classes.infoLabel}>
                Last Updated
              </Typography>
              <Typography className={classes.infoContent}>
                {calculateDaysAgo(techDocMetadata.build_timestamp)}
              </Typography>
            </Box>
          )}
        </Box>
      </Header>
    </>
  );
};
