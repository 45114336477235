import { createPlugin } from '@backstage/core-plugin-api';
import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';
import { InlineSwaggerUi } from './inlineSwaggerUi';

export const inlineSwaggerUiPlugin = createPlugin({
  id: 'inline-swagger-ui',
});

export const InlineSwaggerUiExtension = inlineSwaggerUiPlugin.provide(
  createTechDocsAddonExtension({
    name: 'InlineSwaggerUI',
    location: TechDocsAddonLocations.Content,
    component: InlineSwaggerUi,
  }),
);
