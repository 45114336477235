import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const groupYamlGeneratorPlugin = createPlugin({
  id: 'group-yaml-generator',
  routes: {
    root: rootRouteRef,
  },
});

export const GroupYamlGeneratorPage = groupYamlGeneratorPlugin.provide(
  createRoutableExtension({
    name: 'GroupYamlGeneratorPage',
    // component: () =>
    //   import('./components/ExampleComponent').then(m => m.ExampleComponent),
    component: () =>
      import('./components/GroupYamlGenerator').then(m => m.GroupYamlGenerator),
    mountPoint: rootRouteRef,
  }),
);
