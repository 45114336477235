import React from 'react';
import {
  EmptyState,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import useAsync from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  EntityProvider,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { SidebarNav, SubRoute } from '@xlrt/components';
import { ApiDefinitionCard } from '@backstage/plugin-api-docs';

export type ApiExplorerProps = {
  component?: JSX.Element;
};

export const ApiExplorer = (props: ApiExplorerProps) => {
  const component = props.component || <ApiDefinitionCard />;

  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const { value, loading, error } = useAsync(async (): Promise<SubRoute[]> => {
    const relationshipRefs =
      entity.relations
        ?.filter(r => r.type === 'providesApi' || r.type === 'hasPart')
        .map(r => r.targetRef) ?? [];

    const { items } = await catalogApi.getEntitiesByRefs({
      entityRefs: relationshipRefs,
    });

    return items.flatMap(relatedEntity =>
      relatedEntity && relatedEntity.kind.toLowerCase() === 'api'
        ? [
            {
              path: relatedEntity.metadata.name,
              title:
                relatedEntity.metadata.title || relatedEntity.metadata.name,
              children: (
                <EntityProvider entity={relatedEntity}>
                  {component}
                </EntityProvider>
              ),
            },
          ]
        : [],
    );
  });

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  } else if (!value || !value.length) {
    return (
      <EmptyState title="No APIs provided by this system." missing="content" />
    );
  }

  return <SidebarNav routes={value} />;
};
