import React from 'react';

export const ChinaFlagIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 2H2.5C1.39543 2 0.5 2.89543 0.5 4V12C0.5 13.1046 1.39543 14 2.5 14H13.5C14.6046 14 15.5 13.1046 15.5 12V4C15.5 2.89543 14.6046 2 13.5 2Z"
      fill="#DB362F"
    />
    <path
      opacity="0.15"
      d="M13.5 2H2.5C1.3955 2 0.5 2.8955 0.5 4V12C0.5 13.1045 1.3955 14 2.5 14H13.5C14.6045 14 15.5 13.1045 15.5 12V4C15.5 2.8955 14.6045 2 13.5 2ZM15 12C15 12.827 14.327 13.5 13.5 13.5H2.5C1.673 13.5 1 12.827 1 12V4C1 3.173 1.673 2.5 2.5 2.5H13.5C14.327 2.5 15 3.173 15 4V12Z"
      fill="black"
    />
    <path
      d="M3.979 5.07594L3.595 3.89294L3.2105 5.07594H1.967L2.973 5.80694L2.5885 6.98944L3.595 6.25844L4.601 6.98944L4.2165 5.80694L5.223 5.07594H3.979Z"
      fill="#FFFF00"
    />
    <path
      d="M6.3625 4.09354L6.576 4.44904L6.612 4.03604L7.016 3.94304L6.6345 3.78104L6.671 3.36804L6.399 3.68054L6.0175 3.51854L6.2305 3.87404L5.9585 4.18654L6.3625 4.09354Z"
      fill="#FFFF00"
    />
    <path
      d="M7.4325 5.18597L7.491 5.59647L7.685 5.22997L8.0935 5.30097L7.805 5.00347L7.9985 4.63697L7.6265 4.81947L7.3375 4.52197L7.3965 4.93247L7.024 5.11497L7.4325 5.18597Z"
      fill="#FFFF00"
    />
    <path
      d="M7.7985 6.80603L8.125 6.55053L7.7105 6.56503L7.5685 6.17603L7.4545 6.57453L7.0405 6.58953L7.3845 6.82103L7.2705 7.21953L7.597 6.96403L7.9405 7.19553L7.7985 6.80603Z"
      fill="#FFFF00"
    />
    <path
      d="M6.62999 7.76752L6.64899 7.35352L6.38999 7.67702L6.00249 7.53102L6.22999 7.87702L5.97099 8.20102L6.37099 8.09102L6.59899 8.43752L6.61799 8.02352L7.01799 7.91352L6.62999 7.76752Z"
      fill="#FFFF00"
    />
    <path
      opacity="0.2"
      d="M13.5 2.5H2.5C1.6715 2.5 1 3.1715 1 4V4.5C1 3.6715 1.6715 3 2.5 3H13.5C14.3285 3 15 3.6715 15 4.5V4C15 3.1715 14.3285 2.5 13.5 2.5Z"
      fill="white"
    />
  </svg>
);
