/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Content,
  ContentHeader,
  Header,
  InfoCard,
  Link,
  Page,
  SupportButton,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Grid,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';
import { CodeSnippet } from '@backstage/core-components';
import { ImportStepper } from '@backstage/plugin-catalog-import';
import YAML from 'yaml';

/**
 * Props for {@link ImportInfoCard}.
 *
 * @public
 */
export interface ImportInfoCardProps {
  exampleLocationUrl?: string;
  exampleEntity?: Object;
}

/**
 * Shows information about the import process.
 *
 * @public
 */
export const ImportInfoCard = (props: ImportInfoCardProps) => {
  const {
    exampleLocationUrl = 'https://github.com/cariad-dxp/developer-experience-react/blob/main/catalog-info.yaml',
    exampleEntity = {
      apiVersion: 'backstage.io/v1alpha1',
      kind: 'Component',
      metadata: {
        name: 'frobs-management-project',
        description: 'A component for managing the frobs.',
        title: 'Frobs Management Project',
        links: [
          {
            url: 'mailto: cariad-frobs-team@example.com',
            title: 'Contact maintainers',
            icon: 'email',
          },
          {
            url: 'https://frobs-management.example.com',
            title: 'Frobs management portal',
            icon: 'dashboard',
          },
        ],
      },
      spec: {
        owner: 'frobs-team',
        type: 'service',
        lifecycle: 'experimental',
      },
    },
  } = props;

  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptional('app.title') || 'CARIAD Backstage';
  const [useJson, setUseJson] = useState(false);
  const exampleText: string = useMemo(() => {
    return useJson
      ? JSON.stringify(exampleEntity, undefined, 2)
      : YAML.stringify(exampleEntity, undefined, 2);
  }, [exampleEntity, useJson]);

  return (
    <InfoCard
      title="Register an existing component"
      titleTypographyProps={{ component: 'h3' }}
      deepLink={{
        title: 'Learn more about the CARIAD Software Catalog',
        link: '/catalog/default/Component/xelerate/docs/catalog/',
      }}
    >
      <Typography variant="body2" paragraph>
        <strong>Reminder: Ensure Compliance Before Publishing</strong> <br />
        Before publishing, please ensure that all necessary access restrictions
        are set to comply with confidentiality, eDiscovery, export control, and
        other regulations. For detailed instructions on setting permissions,{' '}
        <a
          target="_blank"
          href="https://developer.cariad.digital/catalog/default/component/xelerate/docs/access_management_basics/"
          style={{ color: '#1EEF97' }}
        >
          click here
        </a>
      </Typography>
      <Typography variant="body2" paragraph>
        <>
          Enter the URL to your catalog file in your source code repository to
          add it to {appTitle}.
          <Typography variant="subtitle2">
            <Link to="/catalog/default/component/xelerate/docs/onboarding/">
              How do I create an entity file?
            </Link>
          </Typography>
        </>
      </Typography>
      <Typography component="h4" variant="h6">
        Link to an existing entity file
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" paragraph>
        Example: <code>{exampleLocationUrl}</code>
      </Typography>
      <Typography variant="body2" paragraph>
        <>
          The wizard analyzes the file, previews the entities, and adds them to
          the {appTitle} catalog.
        </>
      </Typography>
      <Typography component="h4" variant="h6">
        Create a new entity file
      </Typography>
      <Typography variant="body2" paragraph>
        Create a new .yaml file in your code repository. Copy the sample entity
        declaration and replace the default values with values appropriate to
        your project.
      </Typography>
      <Typography variant="body2" paragraph>
        <>
          YAML
          <Tooltip placement="top" arrow title={`${useJson ? 'JSON' : 'YAML'}`}>
            <Switch
              color="primary"
              checked={useJson}
              onChange={() => setUseJson(!useJson)}
              name="YAML JSON Switch"
              inputProps={{ 'aria-label': 'YAML JSON Switch' }}
            />
          </Tooltip>
          JSON
        </>
      </Typography>

      <div style={{ fontSize: '75%' }} data-testid="code-snippet">
        <CodeSnippet
          text={exampleText}
          language={useJson ? 'json' : 'yaml'}
          showCopyCodeButton
        />
      </div>
    </InfoCard>
  );
};

/**
 * The default catalog import page.
 *
 * @public
 */
export const CatalogImportPage = () => {
  const theme = useTheme();
  const configApi = useApi(configApiRef);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const appTitle = configApi.getOptional('app.title') || 'Backstage';

  const contentItems = [
    <Grid item xs={12} md={4} lg={6} xl={8}>
      <ImportInfoCard />
    </Grid>,

    <Grid item xs={12} md={8} lg={6} xl={4}>
      <ImportStepper />
    </Grid>,
  ];

  return (
    <Page themeId="home">
      <Header title="Register an existing CARIAD component" />
      <Content>
        <ContentHeader title={`Start tracking your component in ${appTitle}`}>
          <SupportButton>
            <>
              Start tracking your component in {appTitle} by adding it to the
              software catalog.
            </>
          </SupportButton>
        </ContentHeader>

        <Grid container spacing={2}>
          {isMobile ? contentItems : contentItems.reverse()}
        </Grid>
      </Content>
    </Page>
  );
};
