import { LanguageCode, languageMap, LanguageName } from './dashboard.types';

export function getLanguageName(languageCode: string): LanguageName {
  // Regular expression to match the primary language code
  const regex = /^([a-z]{2})(-[A-Z]{2})?$/i;

  // Extract the primary language code
  const match = regex.exec(languageCode);

  if (match) {
    // Get the primary language code and convert to lowercase
    const primaryCode = match[1].toLowerCase() as LanguageCode;

    // Retrieve the language name from the mapping
    const languageName = languageMap[primaryCode];

    // Check if the language name exists in the mapping
    if (languageName) {
      return capitalize(languageName);
    }
  }

  // Return 'English' default if no match or no language name found
  return 'English';
}

// Helper function to capitalize the first letter of the string
function capitalize(str: string): LanguageName {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as LanguageName;
}
