/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Based on https://github.com/backstage/backstage/blob/aaf5cb2ca122c0c754ff25e415627b4c54e4e102/packages/core-components/src/components/TabbedLayout/RoutedTabs.tsx
 * Modified for vertical display in a responsive sidebar.
 *
 */
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  matchRoutes,
  useNavigate,
  useParams,
  useRoutes,
} from 'react-router-dom';
import { Content } from '@backstage/core-components';
import { SidebarTabs } from './SidebarTabs';
import {
  Box,
  Drawer,
  IconButton,
  TabProps,
  useMediaQuery,
} from '@mui/material/';
import { makeStyles, useTheme } from '@mui/styles';

export type SubRoute = {
  path: string;
  title: string;
  children: JSX.Element;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

const useStyles = makeStyles({
  tabsLayout: {
    display: 'grid',
    justifyContent: 'flex-start',
    paddingTop: 24, // TODO: https://cariad-us.atlassian.net/browse/DXPDP-223 - parameterize these values
  },
  gridLayout: {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
    gridTemplateRows: 'auto',
  },
});

export function useSelectedSubRoute(subRoutes: SubRoute[]): {
  index: number;
  route: SubRoute;
  element: JSX.Element;
} {
  const params = useParams();

  if (!subRoutes.length) {
    throw new Error('No routes provided to SidebarNav.');
  }

  const routes = subRoutes.map(({ path, children }) => ({
    caseSensitive: false,
    path: `${path}/*`,
    element: children,
  }));

  // TODO: remove once react-router updated
  const sortedRoutes = routes.sort((a, b) =>
    // remove "/*" symbols from path end before comparing
    b.path.replace(/\/\*$/, '').localeCompare(a.path.replace(/\/\*$/, '')),
  );

  const element = useRoutes(sortedRoutes) ?? subRoutes[0].children;

  // TODO(Rugvip): Once we only support v6 stable we can always prefix
  // This avoids having a double / prefix for react-router v6 beta, which in turn breaks
  // the tab highlighting when using relative paths for the tabs.
  let currentRoute = params['*'] ?? '';
  if (!currentRoute.startsWith('/')) {
    currentRoute = `/${currentRoute}`;
  }

  const [matchedRoute] = matchRoutes(sortedRoutes, currentRoute) ?? [];
  const foundIndex = matchedRoute
    ? subRoutes.findIndex(t => `${t.path}/*` === matchedRoute.route.path)
    : 0;

  return {
    index: foundIndex === -1 ? 0 : foundIndex,
    element,
    route: subRoutes[foundIndex] ?? subRoutes[0],
  };
}

export function SidebarNav(props: { routes: SubRoute[] }) {
  const { routes } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const { index, route, element } = useSelectedSubRoute(routes);

  const headerTabs = useMemo(
    () =>
      routes.map(t => ({
        id: t.path,
        label: t.title,
        tabProps: t.tabProps,
      })),
    [routes],
  );

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onTabChange = (tabIndex: number) => {
    let { path } = routes[tabIndex];
    // Remove trailing /*
    path = path.replace(/\/\*$/, '');
    // And remove leading / for relative navigation
    path = path.replace(/^\//, '');
    // Note! route resolves relative to the position in the React tree,
    // not relative to current location
    navigate(path);
  };

  const navContent = (
    <div className={classes.tabsLayout}>
      <SidebarTabs
        tabs={headerTabs}
        selectedIndex={index}
        onChange={onTabChange}
      />
    </div>
  );

  const content = (
    <Content>
      <Helmet title={route.title} />
      {element}
    </Content>
  );

  const mobileNav = (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
      >
        Open
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <div role="presentation" onClick={handleDrawerToggle}>
          {navContent}
        </div>
      </Drawer>
      {content}
    </>
  );

  const desktopNav = (
    <div className={classes.gridLayout}>
      <Box>{navContent}</Box>
      <Box>{content}</Box>
    </div>
  );

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  return isMobile ? mobileNav : desktopNav;
}
