import React from 'react';
import { prepareClockObjects } from './world-clock.utils';
import { ClockConfig } from './world-clock.types';
import Box from '@mui/material/Box';
import { useWorldClockStyles } from './world-clock.styles';

/** @public */
export type { ClockConfig };

const WorldClock = (props: {
  clockConfigs: ClockConfig[];
  customTimeFormat?: Intl.DateTimeFormatOptions;
}) => {
  const classes = useWorldClockStyles();
  const { clockConfigs, customTimeFormat } = props;
  const initialClockObject = () => {
    return prepareClockObjects(clockConfigs, customTimeFormat);
  };
  const [clocks, setClocks] = React.useState(initialClockObject);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setClocks(prepareClockObjects(clockConfigs, customTimeFormat));
    }, 10 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [clockConfigs, customTimeFormat]);

  if (clocks.length !== 0) {
    return (
      <div className={classes.root}>
        {clocks.map(clock => (
          <Box key={clock.label} className={classes.container}>
            <Box className={classes.countryContainer}>
              <clock.Icon />
              <Box className={classes.countryLabel}>{clock.label}</Box>
            </Box>
            <Box className={classes.time}>
              <time dateTime={clock.dateTime}>{clock.value}</time>
            </Box>
          </Box>
        ))}
      </div>
    );
  }
  return null;
};

export default WorldClock;
