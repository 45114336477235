import React from 'react';

const AccessDeniedLogo = () => (
  <svg
    width="174"
    height="121"
    viewBox="0 0 174 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M135.931 96.1147C136.722 96.1147 137.363 95.4706 137.363 94.676C137.363 93.8815 136.722 93.2374 135.931 93.2374C135.141 93.2374 134.5 93.8815 134.5 94.676C134.5 95.4706 135.141 96.1147 135.931 96.1147Z"
      fill="#CFCFCF"
    />
    <path
      d="M116.953 46.8363C104.699 46.8363 92.2509 46.3353 80.6073 43.0867C68.9636 39.8381 58.7352 33.7127 49.311 26.553C43.1836 21.8821 37.5869 18.181 29.6583 18.7467C21.882 19.1626 14.445 22.0808 8.44563 27.0701C-1.73452 35.9754 -0.126284 52.4445 3.89431 64.0165C9.97345 81.4715 28.4843 93.5769 44.2128 101.48C62.3859 110.595 82.3442 115.897 102.367 118.951C120.057 121.634 142.46 123.574 157.658 112.05C171.634 101.48 175.461 77.3179 172.036 61.0104C171.206 56.1906 168.656 51.8405 164.863 48.7757C155.053 41.5675 140.418 46.3837 129.385 46.6262C125.316 46.7232 121.135 46.8201 116.953 46.8363Z"
      fill="#616161"
    />
    <path
      d="M146.527 25.1652L35.4142 15.2781C32.1937 14.9916 29.3518 17.3829 29.0667 20.6193L22.1489 99.1343C21.8638 102.371 24.2433 105.227 27.4638 105.513L138.576 115.4C141.797 115.687 144.639 113.296 144.924 110.059L151.842 31.5441C152.127 28.3077 149.747 25.4518 146.527 25.1652Z"
      fill="#EEEEEE"
      stroke="#333333"
      strokeWidth="1.61642"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M119.676 53.8723L56.8468 48.2816C55.3781 48.1509 54.0821 49.2414 53.952 50.7174L53.5648 55.1124C53.4348 56.5883 54.5199 57.8908 55.9886 58.0215L118.818 63.6122C120.287 63.7429 121.583 62.6523 121.713 61.1764L122.1 56.7813C122.23 55.3054 121.145 54.0029 119.676 53.8723Z"
      fill="#EEEEEE"
      stroke="#333333"
      strokeWidth="1.61642"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M117.998 72.8505L55.1685 67.2598C53.6999 67.1291 52.4039 68.2197 52.2738 69.6956L51.8866 74.0907C51.7565 75.5666 52.8417 76.869 54.3104 76.9997L117.14 82.5904C118.608 82.7211 119.904 81.6306 120.034 80.1546L120.422 75.7596C120.552 74.2836 119.467 72.9812 117.998 72.8505Z"
      fill="#EEEEEE"
      stroke="#333333"
      strokeWidth="1.61642"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.5645 90.3193L74.2103 88.4192C73.0867 88.3192 72.0951 89.1536 71.9956 90.2827L71.4978 95.9335C71.3983 97.0627 72.2285 98.0591 73.3521 98.1591L94.7064 100.059C95.83 100.159 96.8215 99.3249 96.921 98.1957L97.4189 92.545C97.5184 91.4158 96.6882 90.4193 95.5645 90.3193Z"
      fill="#00D181"
    />
    <path
      d="M105.632 19.7159C105.632 23.4175 104.539 27.0359 102.493 30.1137C100.447 33.1915 97.538 35.5903 94.135 37.0068C90.7321 38.4234 86.9875 38.794 83.3749 38.0719C79.7623 37.3497 76.444 35.5672 73.8394 32.9498C71.2349 30.3324 69.4612 26.9976 68.7426 23.3671C68.024 19.7366 68.3928 15.9735 69.8024 12.5537C71.212 9.13388 73.599 6.2109 76.6616 4.1544C79.7242 2.0979 83.3248 1.00025 87.0082 1.00025C89.4574 0.987401 91.8849 1.46272 94.1501 2.39872C96.4154 3.33471 98.4735 4.7128 100.205 6.45327C101.937 8.19374 103.309 10.262 104.24 12.5385C105.171 14.815 105.644 17.2545 105.632 19.7159Z"
      fill="#EEEEEE"
      stroke="#333333"
      strokeWidth="1.61642"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M101.193 31.8861C99.4475 33.934 97.2817 35.5781 94.8443 36.7052C92.4069 37.8324 89.7556 38.4161 87.0725 38.4161C84.3894 38.4161 81.738 37.8324 79.3006 36.7052C76.8632 35.5781 74.6975 33.934 72.9521 31.8861C73.1939 28.2881 74.7867 24.9168 77.4082 22.4542C80.0297 19.9917 83.4841 18.6218 87.0725 18.6218C90.6609 18.6218 94.1153 19.9917 96.7368 22.4542C99.3582 24.9168 100.951 28.2881 101.193 31.8861Z"
      fill="#616161"
    />
    <path
      d="M87.0078 15.789C90.383 15.789 93.1191 13.0393 93.1191 9.64743C93.1191 6.25553 90.383 3.50586 87.0078 3.50586C83.6326 3.50586 80.8965 6.25553 80.8965 9.64743C80.8965 13.0393 83.6326 15.789 87.0078 15.789Z"
      fill="#616161"
    />
    <path
      d="M71.0377 57.245C72.5654 57.245 73.8038 56.0003 73.8038 54.4648C73.8038 52.9293 72.5654 51.6846 71.0377 51.6846C69.5099 51.6846 68.2715 52.9293 68.2715 54.4648C68.2715 56.0003 69.5099 57.245 71.0377 57.245Z"
      fill="#616161"
    />
    <path
      d="M82.2296 58.2311C83.7573 58.2311 84.9957 56.9864 84.9957 55.4509C84.9957 53.9154 83.7573 52.6707 82.2296 52.6707C80.7018 52.6707 79.4634 53.9154 79.4634 55.4509C79.4634 56.9864 80.7018 58.2311 82.2296 58.2311Z"
      fill="#616161"
    />
    <path
      d="M93.4249 59.2331C94.9526 59.2331 96.191 57.9883 96.191 56.4529C96.191 54.9174 94.9526 53.6726 93.4249 53.6726C91.8971 53.6726 90.6587 54.9174 90.6587 56.4529C90.6587 57.9883 91.8971 59.2331 93.4249 59.2331Z"
      fill="#616161"
    />
    <path
      d="M104.618 60.2192C106.146 60.2192 107.384 58.9744 107.384 57.4389C107.384 55.9034 106.146 54.6587 104.618 54.6587C103.09 54.6587 101.852 55.9034 101.852 57.4389C101.852 58.9744 103.09 60.2192 104.618 60.2192Z"
      fill="#616161"
    />
  </svg>
);

export default AccessDeniedLogo;
