import React from 'react';

export const CariadXelerateSignInLogo = () => (
  <svg viewBox="0 0 1200 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M655.536 108.66H638.107L590.011 55.9719L638.107 3.28369H655.536L607.439 55.9719L655.536 108.66Z"
      stroke="#1EEF97"
      strokeWidth="5.73"
      strokeMiterlimit="8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M540 1H557.387L605.368 55.8934L557.387 110.787H540L587.981 55.8934L540 1Z"
      fill="#1EEF97"
    />
    <path
      d="M667.98 31.7402H703.101V37.1424H674.359V53.8718H699.596V59.274H674.359V76.5262H703.592V81.9284H667.98V31.7402Z"
      fill="white"
    />
    <path
      d="M723.215 31.7402H729.664V76.3171H757.705V81.9284H723.215V31.7402Z"
      fill="white"
    />
    <path
      d="M776.533 31.7402H811.654V37.1424H782.913V53.8718H808.149V59.274H782.913V76.5262H812.145V81.9284H776.533V31.7402Z"
      fill="white"
    />
    <path
      d="M856.164 31.7402C860.557 31.7402 864.214 33.0066 867.135 35.5392C870.079 38.0718 871.551 41.4991 871.551 45.8208C871.551 49.887 870.243 53.1748 867.626 55.6842C865.032 58.1936 861.725 59.5993 857.706 59.9014L871.551 80.8828V81.9284H864.962L850.836 60.0408H838.113V81.9284H831.769V31.7402H856.164ZM838.113 54.6735H855.253C858.267 54.6735 860.686 53.9183 862.508 52.408C864.331 50.8745 865.242 48.7136 865.242 45.9254C865.242 43.1371 864.331 40.9763 862.508 39.4427C860.686 37.9092 858.267 37.1424 855.253 37.1424H838.113V54.6735Z"
      fill="white"
    />
    <path
      d="M906.275 31.7402H914.547L932.984 80.8828V81.9284H926.57L921.242 67.4993H899.405L894.113 81.9284H887.873V80.8828L906.275 31.7402ZM901.263 61.9926H919.384L910.621 38.1532H910.061L901.263 61.9926Z"
      fill="white"
    />
    <path
      d="M942.73 31.7402H985.632V37.2818H967.405V81.9284H960.956V37.2818H942.73V31.7402Z"
      fill="white"
    />
    <path
      d="M1004.39 31.7402H1039.51V37.1424H1010.77V53.8718H1036V59.274H1010.77V76.5262H1040V81.9284H1004.39V31.7402Z"
      fill="white"
    />
    <line
      x1="503.5"
      y1="1"
      x2="503.5"
      y2="109"
      stroke="white"
      strokeWidth="5"
    />
    <path
      d="M6.993 75.1C2.331 70.2653 0 63.4573 0 54.676C0 45.8947 2.331 39.0867 6.993 34.252C11.6797 29.4173 18.0683 27 26.159 27C29.2177 27 32.0297 27.37 34.595 28.11C37.1603 28.8253 39.3187 29.775 41.07 30.959C42.8213 32.143 44.3137 33.512 45.547 35.066C46.805 36.5953 47.7423 38.1617 48.359 39.765C48.9757 41.3683 49.3457 42.984 49.469 44.612V45.722H43.142C43.0433 44.5627 42.7843 43.4157 42.365 42.281C41.9703 41.1463 41.329 39.9993 40.441 38.84C39.5777 37.6807 38.5293 36.6693 37.296 35.806C36.0627 34.9427 34.4963 34.2397 32.597 33.697C30.6977 33.1297 28.5887 32.846 26.27 32.846C20.1773 32.846 15.4167 34.7577 11.988 38.581C8.584 42.3797 6.882 47.7447 6.882 54.676C6.882 61.6073 8.584 66.9847 11.988 70.808C15.4167 74.6067 20.1773 76.506 26.27 76.506C28.5887 76.506 30.6977 76.2347 32.597 75.692C34.4963 75.1247 36.0627 74.397 37.296 73.509C38.554 72.621 39.627 71.585 40.515 70.401C41.403 69.1923 42.0567 68.0083 42.476 66.849C42.8953 65.665 43.1667 64.4687 43.29 63.26H49.617V64.37C49.469 66.0227 49.0743 67.663 48.433 69.291C47.8163 70.919 46.879 72.5347 45.621 74.138C44.363 75.7167 42.8583 77.1103 41.107 78.319C39.3557 79.5277 37.185 80.502 34.595 81.242C32.0297 81.982 29.2177 82.352 26.159 82.352C18.0683 82.352 11.6797 79.9347 6.993 75.1Z"
      fill="white"
    />
    <path
      d="M110.233 28.036H118.965L138.427 80.206V81.316H131.656L126.032 65.998H102.981L97.3944 81.316H90.8084V80.206L110.233 28.036ZM104.942 60.152H124.071L114.821 34.844H114.229L104.942 60.152Z"
      fill="white"
    />
    <path
      d="M209.226 28.036C213.864 28.036 217.724 29.3803 220.807 32.069C223.915 34.7577 225.469 38.396 225.469 42.984C225.469 47.3007 224.088 50.791 221.325 53.455C218.587 56.119 215.097 57.6113 210.854 57.932L225.469 80.206V81.316H218.513L203.602 58.08H190.171V81.316H183.474V28.036H209.226ZM190.171 52.382H208.264C211.446 52.382 213.999 51.5803 215.923 49.977C217.847 48.349 218.809 46.055 218.809 43.095C218.809 40.135 217.847 37.841 215.923 36.213C213.999 34.585 211.446 33.771 208.264 33.771H190.171V52.382Z"
      fill="white"
    />
    <path d="M271.855 28.036H278.663V81.316H271.855V28.036Z" fill="white" />
    <path
      d="M343.146 28.036H351.878L371.34 80.206V81.316H364.569L358.945 65.998H335.894L330.307 81.316H323.721V80.206L343.146 28.036ZM337.855 60.152H356.984L347.734 34.844H347.142L337.855 60.152Z"
      fill="white"
    />
    <path
      d="M435.922 28.036C443.84 28.036 450.167 30.4287 454.903 35.214C459.664 39.9747 462.044 46.462 462.044 54.676C462.044 62.89 459.664 69.3897 454.903 74.175C450.167 78.9357 443.84 81.316 435.922 81.316H416.386V28.036H435.922ZM423.157 75.47H435.737C441.657 75.47 446.369 73.657 449.871 70.031C453.399 66.3803 455.162 61.262 455.162 54.676C455.162 48.09 453.399 42.984 449.871 39.358C446.369 35.7073 441.657 33.882 435.737 33.882H423.157V75.47Z"
      fill="white"
    />
  </svg>
);
