import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

export const groupauthtokenPlugin = createPlugin({
  id: 'groupauthtoken',
});

// export const GroupAuthTokenPage = groupauthtokenPlugin.provide(
//   createRoutableExtension({
//     name: 'GroupAuthTokenPage',
//     component: () =>
//       import('./components/GroupAuthTokenDisplay').then(m => m.GroupAuthTokenDisplay),
//     mountPoint: rootRouteRef,
//   }),
// );

/** @public */
export const GroupAuthTokenPage: () => JSX.Element | null =
  groupauthtokenPlugin.provide(
    createComponentExtension({
      name: 'EntityAboutCard',
      component: {
        lazy: () =>
          import('./components/GroupAuthTokenDisplay').then(
            m => m.GroupAuthTokenDisplay,
          ),
      },
    }),
  );
