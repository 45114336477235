// import { Entity } from '@backstage/catalog-model';
import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@material-ui/core/Grid';
import AccessDeniedLogo from '../Root/AccessDeniedLogo';
import { Button, makeStyles } from '@material-ui/core';
import WarningFilledIcon from '../Root/WarningFilledIcon';
import { Theme } from '@mui/material/styles';

type ForbidenAccessProps = {
  children?: React.ReactNode;
  entityOwner: string;
};

export const useForbiddenPageStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    padding: '126px 316px 32px 316px',
  },
  button: {
    width: '167px',
    borderRadius: '38px',
    backgroundColor: theme.palette.mode === 'dark' ? '#1EEF97' : '#442EE0',
    textTransform: 'none',
    border:
      theme.palette.mode === 'dark' ? '1px solid #00FF9D' : '1px solid #442EE0',
    color: theme.palette.mode === 'dark' ? '#1D0738' : '#FFFFFF',
    height: '40px',
    gap: '16px',
    padding: '0px 16px',
    '&:hover': {
      backgroundColor: '#78F5C1',
    },
    '&:active': {
      backgroundColor: '#0DBC73',
    },
    textAlign: 'center',
  },
  centerDiv: {
    display: 'flex',
    justifyContent: 'center',
  },

  centerDivGap: {
    display: 'flex',
    justifyContent: 'center',
    height: '120px',
    gap: '24px',
  },
  bottomNote: {
    display: 'flex',
    padding: '1rem',
    gap: '1rem',
    alignSelf: 'stretch',
    borderRadius: '12px',
    background: theme.palette.mode === 'dark' ? '#404040' : '#EEE',
    position: 'fixed',
    bottom: 0,
    marginBottom: '2rem',
    width: '50%',
  },
  bottomGridItem: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
    height: '48px',
  },
  bottomNoteBody: {
    fontSize: '14px',
    lineHeight: '150%',
    color: theme.palette.mode === 'dark' ? '#D7D7D7' : '#757575',
  },
}));

function ForbidenAccess({ entityOwner }: ForbidenAccessProps) {
  const classes = useForbiddenPageStyles();

  return (
    <>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        className={classes.mainGrid}
      >
        <Grid item xs={8} sm={6} md={12}>
          <div className={classes.centerDivGap}>
            <AccessDeniedLogo />
          </div>
        </Grid>
        <Grid item xs={8} sm={6} md={12}>
          <Typography variant="h2" align="center">
            <b>Access Denied</b>
          </Typography>
          <Typography variant="body1" align="center">
            Looks like you’ve hit a 403 Forbidden! <br />
            This asset is in a protected branch, and only the owner can merge
            you in. <br />
            {entityOwner
              ? 'Please reach out to the owner.'
              : 'As no owner was found in our database, please reach out to the XELERATE team directly.'}{' '}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={6} md={12} className={classes.bottomGridItem}>
          <div className={classes.centerDiv}>
            {entityOwner ? (
              <Button
                variant="contained"
                className={classes.button}
                component="a"
                href={`${entityOwner}`}
              >
                Proceed to owner
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.button}
                component="a"
                href="mailto:xelerate@cariad.technology"
              >
                Contact us
              </Button>
            )}
          </div>
        </Grid>

        {entityOwner && (
          <div className={classes.bottomNote}>
            <WarningFilledIcon />
            <Typography variant="body1" className={classes.bottomNoteBody}>
              The XELERATE team is not able to change the access permissions.
              This is handled decentrally by all the respective teams that are
              in charge.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
}

export default ForbidenAccess;
