import { Page } from '@backstage/core-components';
import { TechDocsReaderPageContent } from '@backstage/plugin-techdocs';
import React from 'react';
import { DxpReaderPageHeader } from './dxpReaderPageHeader';

export const DxpReaderPage = (
  <Page themeId="documentation">
    <DxpReaderPageHeader />
    <TechDocsReaderPageContent />
  </Page>
);
