import React from 'react';

const XelerateSidebarLogoIcon = () => (
  <svg
    width="46"
    height="38"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.6099 49.245H45.6431L23.6579 25.161L45.6431 1.077H53.6099L31.6246 25.161L53.6099 49.245Z"
      stroke="#1EEF97"
      strokeWidth="1.65"
      strokeMiterlimit="8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.797852 0.03302H8.74536L30.6779 25.125L8.74536 50.217H0.797852L22.7303 25.125L0.797852 0.03302Z"
      fill="#1EEF97"
    />
  </svg>
);

export default XelerateSidebarLogoIcon;
