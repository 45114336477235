import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useSidebarSubmenuStyles = makeStyles(
  (theme: Theme) => ({
    arrowIcon: {
      color: '#B5B5B5',
      position: 'absolute',
      top: '.75rem',
    },
    supportContainer: {
      position: 'relative',
    },
    supportItem: {
      borderRadius: '0',
      '&--active': {
        '&.MuiButtonBase-root': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#424242' : '#404040',
          borderRadius: '0',
        },
      },
    },
    highlighted: {
      '&.MuiButtonBase-root': {
        color: '#2EFFAF',
      },
    },
  }),
  { name: 'SidebarSubmenu' },
);
