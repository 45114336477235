/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Based on https://github.com/backstage/backstage/blob/aaf5cb2ca122c0c754ff25e415627b4c54e4e102/packages/core-components/src/components/TabbedLayout/RoutedTabs.tsx
 * Modified for vertical display in a responsive sidebar.
 *
 */
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Content } from '@backstage/core-components';
import { SidebarTabsExt } from './SidebarTabsExt';
import {
  Box,
  Drawer,
  IconButton,
  TabProps,
  Tabs,
  TabsClassKey,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

export type SubRouteHeader = {
  title: string;
  subroutes: SubRoute[];
};

export type SubRoute = {
  path: string;
  title: string;
  children: JSX.Element;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

/** @public */
export type HeaderTabsClassKey =
  | 'tabsWrapper'
  | 'defaultTab'
  | 'selected'
  | 'tabRoot'
  | 'wrapper';

const useStyles = makeStyles(
  (theme: Theme) => ({
    tabsLayout: {
      display: 'grid',
      justifyContent: 'flex-start',
      paddingTop: 24, // TODO: https://cariad-us.atlassian.net/browse/DXPDP-223 - parameterize these values
    },
    gridLayout: {
      display: 'grid',
      gridTemplateColumns: '1fr 5fr',
      gridTemplateRows: 'auto',
    },
    tabsWrapper: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      gridArea: 'pageSubheader',
    },
    defaultTab: {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
      maxWidth: 'unset',
      textTransform: 'none',
      width: '100%',
    },
    selected: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    tabRoot: {
      textAlign: 'left',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
    flexContainer: {
      alignItems: 'flex-start',
    },
    wrapper: {
      alignItems: 'baseline',
    },
  }),
  { name: 'BackstageHeaderTabs' },
);

export type Tab = {
  id: string;
  label: string;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

type HeaderTabsProps = {
  routes: SubRouteHeader[];
  onChange?: (subRoute: SubRoute) => void;
};

export function SidebarNavExt(props: HeaderTabsProps) {
  const { routes, onChange } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const styles: ClassNameMap<
    HeaderTabsClassKey | Extract<TabsClassKey, 'flexContainer'>
  > = useStyles();

  const [element, setElement] = React.useState(routes[0].subroutes[0].children);
  const [route, setRoute] = React.useState(routes[0].subroutes[0]);
  const [selectedPath, setSelectedPath] = React.useState(
    routes[0].subroutes[0].path,
  );

  const headerTabs = useMemo(() => routes.map(t => t), [routes]);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onTabChange = (subRoute: SubRoute) => {
    setElement(subRoute.children);
    setRoute(subRoute);

    if (onChange) {
      onChange(subRoute);
    }

    let { path } = subRoute;
    // Remove trailing /*
    path = path.replace(/\/\*$/, '');
    // And remove leading / for relative navigation
    path = path.replace(/^\//, '');
    // Note! route resolves relative to the position in the React tree,
    // not relative to current location

    setSelectedPath(path);

    navigate(path);
  };

  const navContent = (
    <div className={classes.tabsLayout}>
      <Box className={styles.tabsWrapper}>
        <Tabs
          orientation="vertical"
          selectionFollowsFocus
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="tabs"
          classes={{ flexContainer: styles.flexContainer }}
        >
          {headerTabs.map((headerTab, indexTab) => (
            <div key={indexTab}>
              <SidebarTabsExt
                tab={headerTab}
                selectedPath={selectedPath}
                onChange={onTabChange}
              />
            </div>
          ))}
        </Tabs>
      </Box>
    </div>
  );

  const content = (
    <Content>
      <Helmet title={route.title} />
      {element}
    </Content>
  );

  const mobileNav = (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
      >
        Open
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <div role="presentation" onClick={handleDrawerToggle}>
          {navContent}
        </div>
      </Drawer>
      {content}
    </>
  );

  const desktopNav = (
    <div className={classes.gridLayout}>
      <Box>{navContent}</Box>
      <Box>{content}</Box>
    </div>
  );

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  return isMobile ? mobileNav : desktopNav;
}
