import React from 'react';

export const GermanyFlagIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5 5.5H15.5V10.5H0.5V5.5Z" fill="#CC2B1D" />
    <path
      d="M2.5 2H13.5C14.604 2 15.5 2.896 15.5 4V6H0.5V4C0.5 2.896 1.396 2 2.5 2Z"
      fill="black"
    />
    <path
      d="M13.5 14L2.5 14C1.396 14 0.5 13.104 0.5 12V10L15.5 10V12C15.5 13.104 14.604 14 13.5 14Z"
      fill="#F8D147"
    />
    <path
      opacity="0.15"
      d="M13.5 2H2.5C1.3955 2 0.5 2.8955 0.5 4V12C0.5 13.1045 1.3955 14 2.5 14H13.5C14.6045 14 15.5 13.1045 15.5 12V4C15.5 2.8955 14.6045 2 13.5 2ZM15 12C15 12.827 14.327 13.5 13.5 13.5H2.5C1.673 13.5 1 12.827 1 12V4C1 3.173 1.673 2.5 2.5 2.5H13.5C14.327 2.5 15 3.173 15 4V12Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M13.5 2.5H2.5C1.6715 2.5 1 3.1715 1 4V4.5C1 3.6715 1.6715 3 2.5 3H13.5C14.3285 3 15 3.6715 15 4.5V4C15 3.1715 14.3285 2.5 13.5 2.5Z"
      fill="white"
    />
  </svg>
);
