import {
  createPermission,
  ResourcePermission,
} from '@backstage/plugin-permission-common';
import { RESOURCE_TYPE_CATALOG_ENTITY } from '@backstage/plugin-catalog-common/alpha';

export const groupAuthPermission: ResourcePermission<'catalog-entity'> =
  createPermission({
    name: 'group-auth-key-display',
    attributes: { action: 'read' },
    resourceType: RESOURCE_TYPE_CATALOG_ENTITY,
  });

export const groupPermissions = [groupAuthPermission];
