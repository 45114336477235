import React from 'react';
import { submenuOptions } from './sidebar-submenu.types';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GroupsIcon from '@mui/icons-material/Groups';
import TaskIcon from '@mui/icons-material/Task';
import {
  EmailIcon,
  MenuBookIcon,
  SlackLogoIcon,
  StackOverflowIcon,
  SupportIcon,
} from '../Icons/icons';

export const SUBMENU_OPTIONS: Record<submenuOptions, submenuOptions> = {
  create: 'create',
  support: 'support',
  none: 'none',
};

export const SUBMENU_TITLES = {
  CREATE: 'Create',
  SUPPORT: 'Support',
};

export const CREATE_NEW_SIDEBAR_OPTIONS = [
  {
    icon: () => <GroupsIcon sx={{ color: '#B6B5B6' }} />,
    title: 'New Group',
    external: false,
    subtitle: 'Team, Department or B.U',
    tag: 'Beta',
    to: '/create-group',
  },
  {
    icon: () => <TaskIcon sx={{ color: '#B6B5B6' }} />,
    title: 'Register Existing Entity',
    external: false,
    subtitle: 'Using an existing .yaml file in your repo',
    to: '/catalog-import',
  },
  {
    icon: () => <AccountTreeIcon sx={{ color: '#B6B5B6' }} />,
    title: 'New Entity',
    external: false,
    subtitle: 'API, Component or System',
    tag: 'Beta',
    to: '/create-catalog',
  },
];

export const SUPPORT_SIDEBAR_OPTIONS = [
  {
    icon: () => <MenuBookIcon lightMode />,
    title: 'XELERATE Documentation',
    external: false,
    to: '/catalog/default/component/xelerate/docs',
  },
  {
    icon: () => <SupportIcon lightMode />,
    title: 'Create a support ticket',
    external: true,
    to: 'https://devstack.vwgroup.com/jira/servicedesk/customer/portal/251',
  },
  {
    icon: () => <EmailIcon lightMode />,
    title: 'Contact us via mail',
    external: true,
    to: 'mailto:xelerate@cariad.technology?subject=Support%20XELERATE',
  },
  {
    icon: () => <SlackLogoIcon lightMode />,
    title: 'Reach out in Slack',
    external: true,
    to: 'https://cariad.enterprise.slack.com/archives/C06UA5E3TK8',
  },
  {
    icon: () => <StackOverflowIcon lightMode />,
    title: 'Join our SO community',
    external: true,
    to: 'https://stackoverflow.cariad.digital/communities/65',
  },
];
