import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';

export const DocsReaderStyles = () => {
  const { palette } = useTheme();

  const innerCss = `
    .md-content .md-typeset h1, 
    .md-content .md-typeset h1 > a,
    .md-content .md-typeset h2,
    .md-content .md-typeset h2 > a, 
    .md-content .md-typeset h3, 
    .md-content .md-typeset h3 > a,
    .md-content .md-typeset h4,
    .md-content .md-typeset h4 > a, 
    .md-content .md-typeset h5,
    .md-content .md-typeset h5 > a,
    .md-sidebar .md-nav__title {
      color: ${palette.text.secondary};
    }

    .md-content a:hover {
      color: ${palette.primary};
    }

    /* Next/Prev links */
    .md-footer__link {
      background-color: ${palette.background.default};
      width: 13rem;
    }

    .md-footer__title, .md-footer__button {
      color: ${palette.primary.main};
    }

    .md-footer__link:hover {
      opacity: 1;
    }

    .md-footer__link:hover .md-footer__title, .md-footer__link:hover .md-footer__button{
      color: ${palette.text.primary};
    }

    .md-content code {
      border-radius: 6px;
    }

    /* TOC Links */    
    .md-nav__item--active > .md-nav__link,
    .md-nav__item--nested.md-nav__item--active > .md-nav__link {
      font-weight: 700;
      text-decoration: none;
    }

    .md-nav__item--nested.md-nav__item--active > .md-nav__link {
      color: ${palette.primary};
    }

    /* 
      Chromium browsers show a small visible area below the footer.
      This is a workaround to hide it.
    */
    .md-footer__inner {
      padding-bottom: 0;
    }

    .md-footer-meta__inner {
      display: none;
    }

    /* 
      Chromium browsers do not allow the nav sidebar to scroll.
      This workaround enables scrolling if the sidebar content is long enough.  
    */
    @media screen and (min-width: 76.25em) {
      .md-sidebar.md-sidebar--primary {
        height: 100%;
        padding-bottom: 280px;
        scrollbar-color: #e3e3e3 ${palette.background.default};
      }
    }
  `;

  const innerHeadElem = useShadowRootElements<HTMLHeadingElement>(['head'])[0];

  useEffect(() => {
    if (innerHeadElem) {
      if (!innerHeadElem.querySelector('#innerTechDocsCss')) {
        const styleElem = document.createElement('style');
        styleElem.id = 'innerTechDocsCss';
        styleElem.textContent = innerCss;
        innerHeadElem.appendChild(styleElem);
      }
    }
  }, [innerHeadElem, innerCss]);

  // Nothing to render directly, so we can just return null.
  return null;
};
