import { ClockConfig } from '../reusable/world-clock/world-clock.types';
import {
  UsaFlagIcon,
  GermanyFlagIcon,
  EstoniaFlagIcon,
  IndiaFlagIcon,
  ChinaFlagIcon,
} from '../Icons/flags';

export const clockConfigs: ClockConfig[] = [
  {
    label: 'SEA',
    timeZone: 'America/Los_Angeles',
    Icon: UsaFlagIcon,
  },
  {
    label: 'BER',
    timeZone: 'Europe/Berlin',
    Icon: GermanyFlagIcon,
  },
  {
    label: 'TLL',
    timeZone: 'Europe/Tallinn',
    Icon: EstoniaFlagIcon,
  },
  {
    label: 'BLR',
    timeZone: 'Asia/Kolkata',
    Icon: IndiaFlagIcon,
  },
  {
    label: 'PEK',
    timeZone: 'Asia/Shanghai',
    Icon: ChinaFlagIcon,
  },
];

export const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const defaultConfig = [
  {
    component: 'Favorites',
    x: 0,
    y: 0,
    width: 6,
    height: 6,
  },
  {
    component: 'Recently Visited',
    x: 6,
    y: 0,
    width: 6,
    height: 6,
  },
  {
    component: 'DashboardToolkit',
    x: 0,
    y: 6,
    width: 6,
    height: 6,
  },
  {
    component: 'Announcements',
    x: 6,
    y: 6,
    width: 6,
    height: 6,
  },
];

export const dashboardCopy = {
  dashboardTitle: 'Accelerate Your Development with XELERATE',
  dashboardSubtitle:
    'Streamline your onboarding, centralize your documentation, and enhance API management for increased productivity. Explore the full capabilities of XELERATE now!',
};

interface DashboardCta {
  ctaText: string;
  variant: 'primary' | 'secondary';
  to: string;
}

export const dashboardCtas: DashboardCta[] = [
  {
    ctaText: 'Explore Catalog',
    variant: 'secondary',
    to: '/catalog',
  },
  {
    ctaText: 'Add your Project',
    variant: 'primary',
    to: '/catalog-import',
  },
  {
    ctaText: 'Learn about XELERATE',
    variant: 'secondary',
    to: '/about',
  },
];
