/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Based on https://github.com/backstage/backstage/blob/aaf5cb2ca122c0c754ff25e415627b4c54e4e102/packages/core-components/src/layout/HeaderTabs/HeaderTabs.tsx
 * Modified for vertical layout.
 *
 */
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles';
import TabUI, { TabProps } from '@mui/material/Tab';
import Tabs, { TabsClassKey } from '@mui/material/Tabs';
import React, { useCallback, useEffect, useState } from 'react';

// TODO(blam): Remove this implementation when the Tabs are ready
// This is just a temporary solution to implementing tabs for now

/** @public */
export type HeaderTabsClassKey =
  | 'tabsWrapper'
  | 'defaultTab'
  | 'selected'
  | 'tabRoot'
  | 'wrapper';

const useStyles = makeStyles(
  (theme: Theme) => ({
    tabsWrapper: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      gridArea: 'pageSubheader',
    },
    defaultTab: {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
      maxWidth: 'unset',
      textTransform: 'none',
    },
    selected: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    tabRoot: {
      textAlign: 'left',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
    flexContainer: {
      alignItems: 'flex-start',
    },
    wrapper: {
      alignItems: 'baseline',
    },
  }),
  { name: 'BackstageHeaderTabs' },
);

export type Tab = {
  id: string;
  label: string;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

type HeaderTabsProps = {
  tabs: Tab[];
  onChange?: (index: number) => void;
  selectedIndex?: number;
};

/**
 * Vertical Tabs component
 *
 * @public
 *
 */
export function SidebarTabs(props: HeaderTabsProps) {
  const { tabs, onChange, selectedIndex } = props;
  const [selectedTab, setSelectedTab] = useState<number>(selectedIndex ?? 0);
  const styles: ClassNameMap<
    HeaderTabsClassKey | Extract<TabsClassKey, 'flexContainer'>
  > = useStyles();

  const handleChange = useCallback(
    (_: React.ChangeEvent<{}>, index: number) => {
      if (selectedIndex === undefined) {
        setSelectedTab(index);
      }
      if (onChange && selectedIndex !== index) onChange(index);
    },
    [selectedIndex, onChange],
  );

  useEffect(() => {
    if (selectedIndex !== undefined) {
      setSelectedTab(selectedIndex);
    }
  }, [selectedIndex]);

  return (
    <Box className={styles.tabsWrapper}>
      <Tabs
        orientation="vertical"
        selectionFollowsFocus
        indicatorColor="primary"
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="tabs"
        onChange={handleChange}
        value={selectedTab}
        classes={{ flexContainer: styles.flexContainer }}
      >
        {tabs.map((tab, index) => (
          <TabUI
            {...tab.tabProps}
            data-testid={`header-tab-${index}`}
            label={tab.label}
            key={tab.id}
            value={index}
            className={styles.defaultTab}
            classes={{
              selected: styles.selected,
              root: styles.tabRoot,
              wrapped: styles.wrapper,
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
