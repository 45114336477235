import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

export const notificationSettingsPlugin = createPlugin({
  id: 'notification-settings',
});

export const NotificationSettingsCard = notificationSettingsPlugin.provide(
  createComponentExtension({
    name: 'YamlViewer',
    component: {
      lazy: () =>
        import('./components/NotificationsSettings').then(
          m => m.NotificationSettingsCard,
        ),
    },
  }),
);
