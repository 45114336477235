import { makeStyles } from '@mui/styles';

// Uncomment theme if you need to access the theme's values
export const useAboutPageStyles = makeStyles((/* theme */) => ({
  heading1: {
    fontFamily: 'FKCARIAD-Light',
    fontSize: '4rem',
    textAlign: 'center',
    color: '#fff',
  },
  heading2: {
    fontFamily: 'FKCARIAD-Light',
    fontSize: '3rem',
    textAlign: 'center',
    color: '#fff',
  },
  heading3: {
    fontFamily: 'FKCARIAD-Light',
    fontSize: '2rem',
    textAlign: 'center',
    color: '#fff',
  },
  body: {
    fontSize: '1.5rem',
    fontFamily: 'FKCARIAD-Light',
    color: '#fff',
  },
  topContainer: {
    backgroundColor: '#1D0638',
  },
  layoutContainer: {
    maxWidth: '100%',
    margin: '0',
    padding: '0 2rem',
  },
  landingSection: {
    paddingTop: '5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: '1',
  },
  bgGradient: {
    position: 'absolute',
    zIndex: '-1',
    width: '1151px',
  },
  landingCopy: {
    paddingTop: '1.25rem',
    paddingBottom: '2.5rem',
    maxWidth: '59.375rem',
    textAlign: 'center',
    fontFamily: 'FKCARIAD-Light',
  },
  teamSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8.75rem',
    maxWidth: '90rem',
    marginInline: 'auto',
    '@media (max-width: 1299px)': {
      flexDirection: 'column',
      marginTop: '4rem',
    },
  },
  teamCircleContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
    padding: '2rem',
  },
  teamCircle: {
    width: '570px',
    background:
      'radial-gradient(circle, rgba(68, 46, 224, 0.5) 0%, rgba(255, 255, 240, 0) 70%)',
    '@media (max-width: 1299px)': {
      width: '500px',
    },
  },
  teamCTA: {
    width: '50%',
    textAlign: 'left',
    '@media (max-width: 1299px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '0 3.25rem',
    },
  },
  teamHeading: {
    textAlign: 'left',
    maxWidth: '28.125rem',
    '@media (max-width: 1299px)': {
      maxWidth: '38rem',
      textAlign: 'center',
    },
  },
  teamCopy: {
    textAlign: 'left',
    fontSize: '1.25rem',
    fontFamily: 'FKCARIAD-Light',
    maxWidth: '33rem',
    '@media (max-width: 1299px)': {
      maxWidth: 'none',
      textAlign: 'center',
    },
  },
  teamLinks: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '1rem',
  },
  ctaLinks: {
    display: 'flex',
    gap: '1rem',
  },
  screenshotContainer: {
    paddingTop: '5rem',
    width: '100%',
    maxWidth: '900px',
  },
  featuresSection: {},
  testimonialsSection: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5rem',
    marginLeft: '-50px',
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linksSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    padding: '5rem',
  },
  link: {
    fontSize: '1rem',
    color: '#1eef97',
  },
}));
