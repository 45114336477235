import React from 'react';

export const UsaFlagIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 2H2.5C1.39543 2 0.5 2.89543 0.5 4V12C0.5 13.1046 1.39543 14 2.5 14H13.5C14.6046 14 15.5 13.1046 15.5 12V4C15.5 2.89543 14.6046 2 13.5 2Z"
      fill="white"
    />
    <path
      d="M0.819 2.923H15.181C14.8255 2.369 14.2075 2 13.5 2H2.5C1.793 2 1.175 2.369 0.819 2.923Z"
      fill="#A62842"
    />
    <path
      d="M1.015 3.84601C1.011 3.89751 1 3.94701 1 4.00001V4.76951H15.5V4.00001C15.5 3.94751 15.489 3.89801 15.485 3.84601H1.015Z"
      fill="#A62842"
    />
    <path d="M1 5.6925H15.5V6.6155H1V5.6925Z" fill="#A62842" />
    <path d="M1 7.53845H15.5V8.46145H1V7.53845Z" fill="#A62842" />
    <path d="M0.5 9.38452H15.5V10.3075H0.5V9.38452Z" fill="#A62842" />
    <path
      d="M0.5 12C0.5 12.0525 0.5115 12.102 0.5155 12.154H15.4845C15.4885 12.1025 15.5 12.053 15.5 12V11.2305H0.5V12Z"
      fill="#A62842"
    />
    <path
      d="M15.181 13.077H0.819C1.1745 13.631 1.7925 14 2.5 14H13.5C14.207 14 14.825 13.631 15.181 13.077Z"
      fill="#A62842"
    />
    <path d="M2.5 2H8V8.4615H0.5V4C0.5 2.896 1.396 2 2.5 2Z" fill="#102D5E" />
    <path
      opacity="0.15"
      d="M13.5 2H2.5C1.3955 2 0.5 2.8955 0.5 4V12C0.5 13.1045 1.3955 14 2.5 14H13.5C14.6045 14 15.5 13.1045 15.5 12V4C15.5 2.8955 14.6045 2 13.5 2ZM15 12C15 12.827 14.327 13.5 13.5 13.5H2.5C1.673 13.5 1 12.827 1 12V4C1 3.173 1.673 2.5 2.5 2.5H13.5C14.327 2.5 15 3.173 15 4V12Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M13.5 2.5H2.5C1.6715 2.5 1 3.1715 1 4V4.5C1 3.6715 1.6715 3 2.5 3H13.5C14.3285 3 15 3.6715 15 4.5V4C15 3.1715 14.3285 2.5 13.5 2.5Z"
      fill="white"
    />
    <path
      d="M2.3005 3.73151L2.5965 3.51651H2.231L2.118 3.16901L2.005 3.51651H1.6395L1.935 3.73151L1.822 4.07901L2.118 3.86451L2.4135 4.07901L2.3005 3.73151Z"
      fill="white"
    />
    <path
      d="M3.79 3.73151L4.086 3.51651H3.7205L3.6075 3.16901L3.4945 3.51651H3.129L3.4245 3.73151L3.3115 4.07901L3.6075 3.86451L3.903 4.07901L3.79 3.73151Z"
      fill="white"
    />
    <path
      d="M5.28 3.73151L5.5755 3.51651H5.21L5.097 3.16901L4.984 3.51651H4.6185L4.914 3.73151L4.8015 4.07901L5.097 3.86451L5.3925 4.07901L5.28 3.73151Z"
      fill="white"
    />
    <path
      d="M3.03299 4.64148L3.32899 4.42698H2.96349L2.85049 4.07898L2.73749 4.42698H2.37199L2.66749 4.64148L2.55449 4.98948L2.85049 4.77448L3.14599 4.98948L3.03299 4.64148Z"
      fill="white"
    />
    <path
      d="M4.523 4.64148L4.8185 4.42698H4.453L4.34 4.07898L4.227 4.42698H3.8615L4.157 4.64148L4.0445 4.98948L4.34 4.77448L4.6355 4.98948L4.523 4.64148Z"
      fill="white"
    />
    <path
      d="M6.0125 4.64148L6.308 4.42698H5.9425L5.8295 4.07898L5.7165 4.42698H5.351L5.647 4.64148L5.534 4.98948L5.8295 4.77448L6.1255 4.98948L6.0125 4.64148Z"
      fill="white"
    />
    <path
      d="M3.03299 6.46204L3.32899 6.24704H2.96349L2.85049 5.89954L2.73749 6.24704H2.37199L2.66749 6.46204L2.55449 6.80954L2.85049 6.59504L3.14599 6.80954L3.03299 6.46204Z"
      fill="white"
    />
    <path
      d="M4.523 6.46204L4.8185 6.24704H4.453L4.34 5.89954L4.227 6.24704H3.8615L4.157 6.46204L4.0445 6.80954L4.34 6.59504L4.6355 6.80954L4.523 6.46204Z"
      fill="white"
    />
    <path
      d="M6.0125 6.46204L6.308 6.24704H5.9425L5.8295 5.89954L5.7165 6.24704H5.351L5.647 6.46204L5.534 6.80954L5.8295 6.59504L6.1255 6.80954L6.0125 6.46204Z"
      fill="white"
    />
    <path
      d="M6.7695 3.73151L7.065 3.51651H6.6995L6.5865 3.16901L6.4735 3.51651H6.108L6.404 3.73151L6.291 4.07901L6.5865 3.86451L6.8825 4.07901L6.7695 3.73151Z"
      fill="white"
    />
    <path
      d="M2.3005 5.552L2.5965 5.337H2.231L2.118 4.9895L2.005 5.337H1.6395L1.935 5.552L1.822 5.8995L2.118 5.6845L2.4135 5.8995L2.3005 5.552Z"
      fill="white"
    />
    <path
      d="M3.79 5.552L4.086 5.337H3.7205L3.6075 4.9895L3.4945 5.337H3.129L3.4245 5.552L3.3115 5.8995L3.6075 5.6845L3.903 5.8995L3.79 5.552Z"
      fill="white"
    />
    <path
      d="M5.28 5.552L5.5755 5.337H5.21L5.097 4.9895L4.984 5.337H4.6185L4.914 5.552L4.8015 5.8995L5.097 5.6845L5.3925 5.8995L5.28 5.552Z"
      fill="white"
    />
    <path
      d="M6.7695 5.552L7.065 5.337H6.6995L6.5865 4.9895L6.4735 5.337H6.108L6.404 5.552L6.291 5.8995L6.5865 5.6845L6.8825 5.8995L6.7695 5.552Z"
      fill="white"
    />
    <path
      d="M2.3005 7.37195L2.5965 7.15745H2.231L2.118 6.80945L2.005 7.15745H1.6395L1.935 7.37195L1.822 7.71995L2.118 7.50495L2.4135 7.71995L2.3005 7.37195Z"
      fill="white"
    />
    <path
      d="M3.79 7.37195L4.086 7.15745H3.7205L3.6075 6.80945L3.4945 7.15745H3.129L3.4245 7.37195L3.3115 7.71995L3.6075 7.50495L3.903 7.71995L3.79 7.37195Z"
      fill="white"
    />
    <path
      d="M5.28 7.37195L5.5755 7.15745H5.21L5.097 6.80945L4.984 7.15745H4.6185L4.914 7.37195L4.8015 7.71995L5.097 7.50495L5.3925 7.71995L5.28 7.37195Z"
      fill="white"
    />
    <path
      d="M6.7695 7.37195L7.065 7.15745H6.6995L6.5865 6.80945L6.4735 7.15745H6.108L6.404 7.37195L6.291 7.71995L6.5865 7.50495L6.8825 7.71995L6.7695 7.37195Z"
      fill="white"
    />
  </svg>
);
