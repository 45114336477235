import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useDashboardStyles = makeStyles(
  (theme: Theme) => ({
    bannerContainer: {
      marginBottom: '1.5rem',
    },
    grid: {
      '& .react-grid-item': {
        '& hr + div': {
          '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.background.default,
            scrollbarWidth: 'thin',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.background.paper,
            outline: `1px solid ${theme.palette.divider}`,
          },
          '&::-webkit-scrollbar-thumb': {
            maxWidth: '4px',
            backgroundColor: theme.palette.grey[500],
            borderRadius: '10px',
            border: `2px solid ${theme.palette.background.paper}`,
          },
        },
      },
      '& .MuiChip-label': {
        display: 'none',
      },
    },
  }),
  { name: 'Dashboard' },
);
