import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

export const techdocsUploadFrontendPlugin = createPlugin({
  id: 'techdocs-upload-frontend',
});

/** @public */
export const UserApiAuthCard: () => JSX.Element | null =
  techdocsUploadFrontendPlugin.provide(
    createComponentExtension({
      name: 'EntityAboutCard',
      component: {
        lazy: () => import('./components/ApiAuthCard').then(m => m.ApiAuthCard),
      },
    }),
  );

/** @public */
export const GroupApiAuthCard: () => JSX.Element | null =
  techdocsUploadFrontendPlugin.provide(
    createComponentExtension({
      name: 'EntityAboutCard',
      component: {
        lazy: () =>
          import('./components/ApiAuthCardGroup').then(m => m.ApiAuthCardGroup),
      },
    }),
  );
