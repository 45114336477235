import React from 'react';
import {
  EmptyState,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import useAsync from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  EntityProvider,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  ReleaseNoteEntity,
  RELEASE_NOTE_KIND_NAME,
} from '@internal/plugin-release-notes-common';
import { ApiReleaseNoteContent } from './ApiReleaseNoteContent';
import { SubRouteHeader, SidebarNavExt } from '@xlrt/components';

export type ApiReleaseNotesProps = {
  component?: JSX.Element;
};

export const ApiReleaseNotes = () => {
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const { value, loading, error } = useAsync(
    async (): Promise<SubRouteHeader[]> => {
      const relationshipRefs =
        entity.relations
          ?.filter(r => r.type === 'developer.cariad.digital/v1-hasReleaseNote')
          .map(r => r.targetRef) ?? [];

      const { items } = await catalogApi.getEntitiesByRefs({
        entityRefs: relationshipRefs,
      });

      const headers = new Map<string, SubRouteHeader>();

      const releaseNotesList = items.filter(relatedEntity => {
        return (
          relatedEntity &&
          relatedEntity.kind.toLowerCase() ===
            RELEASE_NOTE_KIND_NAME.toLowerCase() &&
          relatedEntity.spec?.environment
        );
      });

      releaseNotesList.sort((item1, item2) => {
        if (item1 && item2) {
          const releaseNote1 = item1 as ReleaseNoteEntity;
          const releaseNote2 = item2 as ReleaseNoteEntity;

          if (
            releaseNote1.spec?.deploymentDate <
            releaseNote2.spec?.deploymentDate
          ) {
            return 1;
          }
          if (
            releaseNote1.spec?.deploymentDate >
            releaseNote2.spec?.deploymentDate
          ) {
            return -1;
          }
        }

        // names must be equal
        return 0;
      });

      releaseNotesList.forEach(relatedEntity => {
        const releaseNote = relatedEntity as ReleaseNoteEntity;

        if (headers.has(releaseNote.spec.environment)) {
          headers.get(releaseNote.spec.environment)?.subroutes.push({
            path: releaseNote.metadata.name,
            title: releaseNote.metadata.title || releaseNote.metadata.name,
            children: (
              <EntityProvider entity={releaseNote}>
                <ApiReleaseNoteContent releaseNote={releaseNote} />
              </EntityProvider>
            ),
          });
        } else {
          const subRouteHeader: SubRouteHeader = {
            title: releaseNote.spec.environment,
            subroutes: [
              {
                path: releaseNote.metadata.name,
                title: releaseNote.metadata.title || releaseNote.metadata.name,
                children: (
                  <EntityProvider entity={releaseNote}>
                    <ApiReleaseNoteContent releaseNote={releaseNote} />
                  </EntityProvider>
                ),
              },
            ],
          };

          headers.set(releaseNote.spec.environment, subRouteHeader);
        }
      });

      return Array.from(headers.values());
    },
  );

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  } else if (!value || !value.length) {
    return (
      <EmptyState
        title="No Release Notes provided by this API."
        missing="content"
      />
    );
  }

  return <SidebarNavExt routes={value} />;
};
