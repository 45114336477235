import {
  CompoundEntityRef,
  Entity,
  KindValidator,
  entityKindSchemaValidator,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import schema from '../schema/kinds/releaseNote.json';
import { InputError } from '@backstage/errors';
import { CatalogClient } from '@backstage/catalog-client';

export const RELEASE_NOTE_KIND_NAME: string = 'ReleaseNote';
export interface ReleaseNoteEntity extends Entity {
  apiVersion: 'developer.cariad.digital/v1';
  kind: typeof RELEASE_NOTE_KIND_NAME;
  spec: {
    affectedService: string;
    deploymentDate: number;
    environment: string;
    overallSummary: string;
    owner: string;
    version: string;
    workItems: Array<{
      workItemTitle: string;
      workItemNumber: string;
      workItemUri: string;
      releaseNotesDescription: string;
      commitHash: string;
      workItemFlavor: 'bugFix' | 'userStory';
      isBreakingChange: boolean;
      dxpCaseId?: string;
    }>;
  };
}

export function parseServiceRef(affectedService: string): CompoundEntityRef {
  if (!affectedService) {
    throw new InputError(
      'No affected service provided. Specify the service in the format {namespace}/{kind}/{name}.',
    );
  }

  if (affectedService.split('/').length !== 3) {
    throw new InputError(
      `Affected service ${affectedService} does not exist. Specify the service in the format {namespace}/{kind}/{name}.`,
    );
  }

  const servicePath = affectedService.split('/');
  const serviceEntityRef: CompoundEntityRef = {
    namespace: servicePath[0],
    kind: servicePath[1],
    name: servicePath[2],
  };
  return serviceEntityRef;
}

export const releaseNoteFromApi = async (
  json: any,
  catalogClient: CatalogClient,
  token: string,
): Promise<ReleaseNoteEntity> => {
  const ticks = Date.parse(json.deploymentDate);

  if (!json.deploymentDate || isNaN(ticks)) {
    throw new InputError(
      `Invalid deployment date format. Must be a valid ISO 8601 date string.`,
    );
  }

  const serviceEntityRef = parseServiceRef(json.affectedService);
  const serviceEntityRefString = stringifyEntityRef(serviceEntityRef);
  const serviceEntity = await catalogClient.getEntityByRef(
    serviceEntityRefString,
    { token },
  );

  if (!serviceEntity) {
    throw new InputError(
      `Affected service ${json.affectedService} does not exist. Specify the service in the format {namespace}/{kind}/{name}.`,
    );
  }

  if (serviceEntity.metadata.name.length > 39) {
    throw new InputError(
      `Release Notes are not available for services with names longer than 39 characters.`,
    );
  }

  if (!json.environment || !json.environment.trim()) {
    throw new InputError(`Environment must be provided.`);
  }

  const regexForEntityName: RegExp = /[^a-z0-9A-Z\-_\.]/;

  if (
    json.environment.match(regexForEntityName) ||
    json.environment.length > 12
  ) {
    throw new InputError(
      `Environment name must be fewer than 13 characters, and may only contain alphanumeric characters, hyphens, underscores and dots.`,
    );
  }

  if (!json.version || !json.version.trim()) {
    throw new InputError(`Version must be provided.`);
  }

  if (json.version.match(regexForEntityName) || json.version.length > 12) {
    throw new InputError(
      `Version name must be fewer than 13 characters, and may only contain alphanumeric characters, hyphens, underscores and dots.`,
    );
  }

  const serviceTitleOrName =
    serviceEntity.metadata.title || serviceEntity.metadata.name;

  return {
    apiVersion: 'developer.cariad.digital/v1',
    kind: RELEASE_NOTE_KIND_NAME,
    metadata: {
      name: `${serviceEntity.metadata.name}-${json.environment}-${json.version}`,
      title: `${serviceTitleOrName} : ${json.environment} : ${json.version}`,
    },
    spec: {
      affectedService: serviceEntityRefString,
      deploymentDate: ticks,
      environment: json.environment,
      overallSummary: json.overallSummary,
      owner: serviceEntity.spec?.owner?.toString() || 'guest',
      version: json.version,
      workItems: (json.workItems || []).map((wi: any) => {
        return {
          workItemTitle: wi.workItemTitle,
          workItemNumber: wi.workItemNumber,
          workItemUri: wi.workItemUri,
          releaseNotesDescription: wi.releaseNotesDescription,
          commitHash: wi.commitHash,
          workItemFlavor: wi.workItemFlavor,
          isBreakingChange: wi.isBreakingChange || false,
          dxpCaseId: wi.dxpCaseId || undefined,
        };
      }),
    },
  };
};

function ajvCompiledJsonSchemaValidator(
  releaseNoteSchema: unknown,
): KindValidator {
  let validator: undefined | ((data: unknown) => any);
  return {
    async check(data: unknown) {
      if (!validator) {
        validator = entityKindSchemaValidator(releaseNoteSchema);
      }
      return validator(data) === data;
    },
  };
}
export const releaseNoteEntityValidator = ajvCompiledJsonSchemaValidator;

export const releaseNoteSchema = schema;
