/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@backstage/core-components';
import ErrorBackground from './error_bg.png';

interface IErrorPageProps {
  status: string;
  statusMessage: string;
  additionalInfo?: React.ReactNode;
  supportUrl?: string;
}

/** @public */
export type ErrorPageClassKey = 'container' | 'title' | 'subtitle';

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      padding: theme.spacing(8),
      backgroundImage: `url(${ErrorBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: '100vh',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
    },
    title: {
      color: '#ffffff',
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(4),
        fontSize: theme.typography.h3.fontSize,
      },
    },
    subtitle: {
      color: '#cccccc',
    },
  }),
  { name: 'BackstageErrorPage' },
);

/**
 * Error page with status and description
 *
 * @public
 *
 */
export function ErrorPage(props: IErrorPageProps) {
  const { status, statusMessage, additionalInfo, supportUrl } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const cariadBackstageSupportUrl =
    '/docs/default/Component/xelerate/faq/#contact-information';

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} sm={8} md={4}>
        <Typography
          data-testid="error"
          variant="body1"
          className={classes.subtitle}
        >
          ERROR {status}: {statusMessage}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {additionalInfo}
        </Typography>
        <Typography variant="h2" className={classes.title}>
          <div>Woah there, Speed Racer!</div>
          <div>You've ventured off-track.</div>
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          <Link to="#" data-testid="go-back-link" onClick={() => navigate(-1)}>
            Go back
          </Link>
          {'  '}
          to the main route, or give our{'  '}
          <Link to={supportUrl || cariadBackstageSupportUrl}>
            support crew
          </Link>{' '}
          a shout for assistance.
        </Typography>
      </Grid>
    </Grid>
  );
}
