export type LanguageCode =
  | 'en'
  | 'de'
  | 'et'
  | 'hi'
  | 'zh'
  | 'sv'
  | 'ar'
  | 'es';
export type LanguageName =
  | 'English'
  | 'German'
  | 'Estonian'
  | 'Hindi'
  | 'Chinese'
  | 'Swedish'
  | 'Arabic'
  | 'Spanish';

export const languageMap: Record<LanguageCode, LanguageName> = {
  en: 'English',
  de: 'German',
  et: 'Estonian',
  hi: 'Hindi',
  zh: 'Chinese',
  sv: 'Swedish',
  ar: 'Arabic',
  es: 'Spanish',
};
