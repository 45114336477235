import { useEffect, useState } from 'react';
import { useTechDocsReaderPage } from '@backstage/plugin-techdocs-react';

export const InlineSwaggerUi = () => {
  const { shadowRoot } = useTechDocsReaderPage();
  const innerHeadElem = shadowRoot?.querySelector('head');

  useEffect(() => {
    if (innerHeadElem) {
      // Load SwaggerUI Styles
      const linkTag = document.createElement('link');
      linkTag.type = 'text/css';
      linkTag.rel = 'stylesheet';
      linkTag.href = 'https://unpkg.com/swagger-ui-dist@4.5.0/swagger-ui.css';
      innerHeadElem.appendChild(linkTag);
    }
  }, [innerHeadElem]);

  // Load SwaggerUI
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src =
      'https://unpkg.com/swagger-ui-dist@4.5.0/swagger-ui-bundle.js';
    scriptTag.addEventListener('load', () => setLoaded(true));
    document.body.appendChild(scriptTag);
  }, []);

  // Initialize SwaggerUI
  interface WindowWithSwaggerUIBundle extends Window {
    SwaggerUIBundle: any;
  }
  useEffect(() => {
    if (!loaded) return;

    const scriptElements =
      shadowRoot?.querySelectorAll('div[data-dom-id]') ?? [];

    scriptElements.forEach(element => {
      const swaggerUrl = element.getAttribute('data-swagger-url');
      const domId = element.getAttribute('data-dom-id');

      if (domId && swaggerUrl) {
        const swaggerTargetElement = shadowRoot?.querySelector(domId);

        // eslint-disable-next-line new-cap
        (window as any as WindowWithSwaggerUIBundle).SwaggerUIBundle({
          url: swaggerUrl,
          domNode: swaggerTargetElement,
        });
      }
    });
  }, [loaded, shadowRoot]);

  // Nothing to render directly, so we can just return null.
  return null;
};
