import React from 'react';
import { NavLink } from 'react-router-dom';
import { sidebarConfig, useSidebarOpenState } from '@backstage/core-components';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import XelerateSidebarLogoFull from './XelerateSidebarLogoFull';
import XelerateSidebarLogoIcon from './XelerateSidebarLogoIcon';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();
  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
        aria-label="Home"
        style={{ marginLeft: isOpen ? '25px' : '10px' }}
      >
        {isOpen ? <XelerateSidebarLogoFull /> : <XelerateSidebarLogoIcon />}
      </Link>
    </div>
  );
};

export default SidebarLogo;
