import { createPlugin } from '@backstage/core-plugin-api';
import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';
import { DocsReaderStyles } from './docsReaderStyles';

export const readerStylesPlugin = createPlugin({
  id: 'reader-styles',
});

export const ReaderStylesExtension = readerStylesPlugin.provide(
  createTechDocsAddonExtension({
    name: 'ReaderStylesExtension',
    location: TechDocsAddonLocations.Content,
    component: DocsReaderStyles,
  }),
);
