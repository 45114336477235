import React from 'react';
import { IconComponent } from '@backstage/core-plugin-api';
import { IconMapping } from '../../GroupYamlGenerator/models/group-yaml-generator.model';
import SvgIcon from '@mui/material/SvgIcon';
import GroupIcon from '@mui/icons-material/Group';
import SystemIcon from '@mui/icons-material/Category';
import TemplateIcon from '@mui/icons-material/LayersOutlined';
import ComponentIcon from '@mui/icons-material/Memory';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import SubjectIcon from '@mui/icons-material/Subject';
import SearchIcon from '@mui/icons-material/Search';
import ChatIcon from '@mui/icons-material/Chat';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import MailIcon from '@mui/icons-material/Mail';
import GitHubIcon from '@mui/icons-material/GitHub';
import HelpIcon from '@mui/icons-material/Help';
import PersonIcon from '@mui/icons-material/Person';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import LanguageIcon from '@mui/icons-material/Language';

type IconProps = {
  fillColor?: string;
  width?: number;
  height?: number;
};

const LeanIXIcon = ({
  fillColor = 'currentColor',
  width = 18,
  height = 12,
}: IconProps) => (
  <SvgIcon width={width} height={height} viewBox="0 0 18 12" fill="none">
    <g id="LeanIX_Logo_90x90">
      <g id="Elemente/Logo/LeanIX-Symbol-Weiss-Copy">
        <g id="Rectangle-Copy-5">
          <path
            id="Mask"
            opacity="0.6"
            d="M11.8918 11.8337C15.1296 11.8337 17.7543 9.22199 17.7543 6.00033C17.7543 2.77866 15.1296 0.166992 11.8918 0.166992C8.65403 0.166992 6.0293 2.77866 6.0293 6.00033C6.0293 9.22199 8.65403 11.8337 11.8918 11.8337Z"
            fill={fillColor}
          />
        </g>
        <path
          id="Rectangle-Copy-6"
          opacity="0.6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 6L6.03 0L12.06 6L6.03 12L0 6Z"
          fill={fillColor}
        />
        <path
          id="Combined-Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.83104 1.79297L12.0593 6.00032L7.83104 10.2077C6.7205 9.14619 6.0293 7.65357 6.0293 6.00032C6.0293 4.34707 6.7205 2.85445 7.83104 1.79297Z"
          fill={fillColor}
        />
      </g>
    </g>
  </SvgIcon>
);

const ConfluenceIcon = ({
  fillColor = 'currentColor',
  width = 13,
  height = 12,
}: IconProps) => (
  <SvgIcon width={width} height={height} viewBox="0 0 13 12" fill="none">
    <g id="Group">
      <path
        id="Vector"
        d="M0.452862 9.16085C0.323704 9.37147 0.178649 9.61588 0.0554522 9.81061C-0.0548186 9.99696 0.00437913 10.2373 0.188584 10.3511L2.77175 11.9407C2.86242 11.9967 2.97178 12.0139 3.07527 11.9885C3.17876 11.9631 3.26772 11.8972 3.32216 11.8056C3.42548 11.6327 3.55862 11.4082 3.70367 11.1678C4.727 9.47877 5.75629 9.68543 7.61219 10.5717L10.1735 11.7897C10.2705 11.8359 10.3821 11.8408 10.4828 11.8034C10.5835 11.7659 10.6647 11.6893 10.708 11.591L11.938 8.80914C12.0249 8.61046 11.9365 8.37879 11.7393 8.28853C11.1988 8.03419 10.1238 7.52749 9.15613 7.06054C5.67482 5.36956 2.71611 5.47885 0.452862 9.16085Z"
        fill={fillColor}
      />
      <path
        id="Vector_2"
        d="M12.0353 2.84767C12.1644 2.63705 12.3095 2.39264 12.4327 2.19791C12.5429 2.01157 12.4838 1.77124 12.2995 1.65743L9.71638 0.0677948C9.62497 0.00622447 9.51209 -0.0146177 9.40472 0.0102479C9.29734 0.0351134 9.20512 0.103453 9.15007 0.19894C9.04675 0.371813 8.91362 0.596349 8.76856 0.836782C7.74523 2.52577 6.71594 2.31912 4.86004 1.4329L2.30668 0.220797C2.20968 0.174625 2.09811 0.169695 1.99741 0.20713C1.89671 0.244565 1.81546 0.321176 1.77217 0.419502L0.542186 3.20137C0.455286 3.40005 0.543708 3.63172 0.740891 3.72197C1.28137 3.97632 2.35636 4.48301 3.32405 4.94997C6.81331 6.63896 9.77202 6.5257 12.0353 2.84767Z"
        fill={fillColor}
      />
    </g>
  </SvgIcon>
);

const JiraIcon = ({
  fillColor = 'currentColor',
  width = 12,
  height = 12,
}: IconProps) => (
  <SvgIcon width={width} height={height} viewBox="0 0 12 12" fill="none">
    <g id="Group 10">
      <path
        id="Vector"
        d="M11.4983 0H5.71973C5.71973 0.691832 5.99456 1.35533 6.48376 1.84453C6.97295 2.33373 7.63645 2.60856 8.32828 2.60856H9.39277V3.63634C9.39371 5.07569 10.5603 6.24231 11.9996 6.24325V0.501295C11.9996 0.224516 11.7753 0 11.4983 0Z"
        fill={fillColor}
      />
      <path
        id="Vector_2"
        d="M8.63989 2.87927H2.86133C2.86222 4.31863 4.02879 5.48524 5.46819 5.48618H6.53268V6.51725C6.53451 7.95661 7.70188 9.12243 9.14123 9.12243V3.38066C9.14123 3.10379 8.91677 2.87927 8.63989 2.87927Z"
        fill={fillColor}
      />
      <path
        id="Vector_3"
        d="M5.77861 5.75684H0C0 7.19751 1.16793 8.36539 2.60856 8.36539H3.67638V9.39313C3.67732 10.8312 4.84187 11.9973 6.27995 12V6.25818C6.27995 5.98131 6.05548 5.75684 5.77861 5.75684Z"
        fill={fillColor}
      />
    </g>
  </SvgIcon>
);

const SlackIcon = ({
  fillColor = 'currentColor',
  width = 12,
  height = 12,
}: IconProps) => (
  <SvgIcon width={width} height={height} viewBox="0 0 12 12" fill="none">
    <g id="_x33_06-slack">
      <g id="Group">
        <path
          id="Vector"
          d="M2.52105 7.58317C2.52105 8.27698 1.95426 8.84377 1.2605 8.84377C0.566768 8.84377 0 8.27695 0 7.58317C0 6.88946 0.566794 6.32269 1.2605 6.32269H2.52105V7.58317Z"
          fill={fillColor}
        />
        <path
          id="Vector_2"
          d="M3.15625 7.58317C3.15625 6.88946 3.72307 6.32269 4.4168 6.32269C5.11054 6.32269 5.67733 6.88949 5.67733 7.58317V10.7397C5.67733 11.4334 5.11051 12.0002 4.4168 12.0002C3.72304 12.0002 3.15625 11.4333 3.15625 10.7397V7.58317Z"
          fill={fillColor}
        />
        <path
          id="Vector_3"
          d="M4.4168 2.52114C3.72304 2.52114 3.15625 1.95432 3.15625 1.26059C3.15625 0.566855 3.72307 6.10352e-05 4.4168 6.10352e-05C5.11054 6.10352e-05 5.67733 0.566881 5.67733 1.26059V2.52114H4.4168Z"
          fill={fillColor}
        />
        <path
          id="Vector_4"
          d="M4.41693 3.15662C5.11067 3.15662 5.67746 3.72341 5.67746 4.41717C5.67746 5.1109 5.11064 5.67764 4.41693 5.67764H1.2605C0.566768 5.67764 0 5.11088 0 4.41717C0 3.72338 0.566794 3.15662 1.2605 3.15662H4.41693Z"
          fill={fillColor}
        />
        <path
          id="Vector_5"
          d="M9.47852 4.41717C9.47852 3.72338 10.0453 3.15662 10.739 3.15662C11.4328 3.15662 11.9995 3.72341 11.9995 4.41717C11.9995 5.1109 11.4327 5.67764 10.739 5.67764H9.47852V4.41717Z"
          fill={fillColor}
        />
        <path
          id="Vector_6"
          d="M8.84335 4.41705C8.84335 5.11078 8.27653 5.67752 7.58279 5.67752C6.88906 5.67752 6.32227 5.11075 6.32227 4.41705V1.26059C6.32227 0.566855 6.88909 6.10352e-05 7.58279 6.10352e-05C8.27655 6.10352e-05 8.84335 0.566881 8.84335 1.26059V4.41705Z"
          fill={fillColor}
        />
        <path
          id="Vector_7"
          d="M7.58279 9.47888C8.27655 9.47888 8.84335 10.0457 8.84335 10.7394C8.84335 11.4331 8.27653 11.9999 7.58279 11.9999C6.88906 11.9999 6.32227 11.4331 6.32227 10.7394V9.47888H7.58279Z"
          fill={fillColor}
        />
        <path
          id="Vector_8"
          d="M7.58279 8.84377C6.88906 8.84377 6.32227 8.27695 6.32227 7.58317C6.32227 6.88946 6.88909 6.32269 7.58279 6.32269H10.7392C11.433 6.32269 11.9997 6.88949 11.9997 7.58317C11.9997 8.27698 11.433 8.84377 10.7392 8.84377H7.58279Z"
          fill={fillColor}
        />
      </g>
    </g>
  </SvgIcon>
);

export const StackOverflowIcon = ({
  fillColor = 'currentColor',
  width = 12,
  height = 12,
}: IconProps) => (
  <SvgIcon width={width} height={height} viewBox="0 0 105 125">
    <g fill="none" fillRule="evenodd">
      <polygon
        fill={fillColor}
        points="88 80 99 80 99 124 0 124 0 80 11 80 11 113 88 113"
      />
      <path
        fill={fillColor}
        fillRule="nonzero"
        d="M22.9878906,76.73 L77.0128906,88.085 L79.2838906,77.285 L25.2588906,65.925 L22.9878906,76.73 Z M30.1368906,50.861 L80.1828906,74.169 L84.8448906,64.16 L34.7978906,40.852 L30.1368906,50.861 Z M43.9848906,26.308 L86.4128906,61.639 L93.4788906,53.154 L51.0508906,17.824 L43.9848906,26.308 Z M71.3718906,0.192 L62.5118906,6.782 L95.4598906,51.082 L104.319891,44.493 L71.3718906,0.192 Z M22,102 L77,102 L77,91 L22,91 L22,102 Z"
      />
    </g>
  </SvgIcon>
);

const customIcons = {
  leanix: LeanIXIcon as IconComponent,
  confluence: ConfluenceIcon as IconComponent,
  jira: JiraIcon as IconComponent,
  slack: SlackIcon as IconComponent,
  stackOverflow: StackOverflowIcon as IconComponent,
};

export const iconMapping: IconMapping = {
  group: GroupIcon,
  system: SystemIcon,
  template: TemplateIcon,
  component: ComponentIcon,
  brokenImage: BrokenImageIcon,
  catalog: MenuBookIcon,
  scaffolder: CreateNewFolderIcon,
  techdocs: SubjectIcon,
  search: SearchIcon,
  chat: ChatIcon,
  dashboard: DashboardIcon,
  docs: DescriptionIcon,
  email: MailIcon,
  github: GitHubIcon,
  help: HelpIcon,
  user: PersonIcon,
  warning: ReportProblemIcon,
  default: LanguageIcon,
  ...customIcons,
};
